import React from "react"

class ImageFade extends React.Component {
  render() {
    return (
      <img
        data-src={this.props.src}
        alt={this.props.alt}
        className="img is-fade lazyload"
      />
    )
  }
}

export default ImageFade
